import {
  Chart,
  DoughnutController,
  DoughnutControllerChartOptions,
  DoughnutControllerDatasetOptions,
  UpdateMode,
} from "chart.js";
import { AnyObject } from "chart.js/types/basic";

declare module "chart.js" {
  interface ChartTypeRegistry {
    gauge: //ChartTypeRegistry["doughnut"]
    {
      chartOptions: IGaugeChartOptions;
      datasetOptions: GaugeControllerDatasetOptions;
      defaultDataPoint: DoughnutDataPoint;
      scales: keyof CartesianScaleTypeRegistry;
      metaExtensions: {};
      parsedDataType: ChartTypeRegistry["doughnut"]["parsedDataType"];
    };
  }

  export interface ElementOptionsByType<TType extends ChartType> {
    gauge: ScriptableAndArrayOptions<IGaugeOptions & CommonHoverOptions, ScriptableContext<TType>>;
  }

  export interface ElementChartOptionsByType<TType extends ChartType> {
    gauge: ScriptableAndArrayOptions<IGaugeChartOptions & CommonHoverOptions, ScriptableContext<TType>>;
  }
}
export interface IGaugeChartOptions extends DoughnutControllerChartOptions {
  needle: {
    radiusPercentage: number;
    widthPercentage: number;
    lengthPercentage: number;
    color: string;
    maxOverflow: number;
  };
  valueLabel: {
    fontSize: string;
    fontWeight: string;
    display: true;
    formatter(value: number): string | string[];
    color: string;
    backgroundColor: string;
    borderRadius: number;
    padding: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
    bottomMarginPercentage: number;
    leftMarginPercentage: number;
  };
  defaultFontFamily: string;
  defaultFontColor: string;
}
export interface IGaugeOptions {
  value: number;
  minValue: number;
  data: number[];
}
export interface GaugeControllerDatasetOptions extends DoughnutControllerDatasetOptions, IGaugeOptions {}
//ScriptableAndArrayOptions<DoughnutControllerDatasetOptions, ScriptableContext<"gauge">>,
//ScriptableAndArrayOptions<CommonHoverOptions, ScriptableContext<"gauge">>,
//AnimationOptions<"gauge"> {}

//DoughnutControllerDatasetOptions

class Gauge extends DoughnutController {
  getValuePercent({ minValue, data }: GaugeControllerDatasetOptions, value: number) {
    const min = minValue || 0;
    const max = data.reduce((accumulator, currentValue) => accumulator + currentValue, min);
    const length = max - min;
    const percent = (value - min) / length;
    return percent;
  }
  getWidth(chart: Chart) {
    return chart.chartArea.right - chart.chartArea.left;
  }
  getTranslation(chart: Chart) {
    const { chartArea } = chart;
    const centerX = chartArea.width / 2;
    //const centerY = chartArea.height / 2;
    const dx = centerX;
    const dy = chartArea.bottom * 0.92;
    return { dx, dy };
  }
  getAngle(chart: Chart, valuePercent: number) {
    const { circumference, needle } = this._options();
    const angle = circumference * valuePercent;
    return Math.min(Math.max(angle, 0 - needle.maxOverflow), circumference + needle.maxOverflow);
  }
  /* TODO set min padding, not applied until chart.update() (also chartArea must have been set)
  setBottomPadding(chart) {
    const needleRadius = this.getNeedleRadius(chart);
    const padding = this.chart.config.options.layout.padding;
    if (needleRadius > padding.bottom) {
      padding.bottom = needleRadius;
      return true;
    }
    return false;
  },
  */
  _drawNeedle(ease: number) {
    const options = this._options();
    const { ctx, config } = this.chart;
    const dataset = config.data.datasets[this.index] as GaugeControllerDatasetOptions;

    if (dataset.value === null) {
      return;
    }

    const { previous } = this.getMeta() as any;
    const outerRadius = options.radius.toString().indexOf("%")
      ? (parseFloat(options.radius.toString()) / 100) * this.chart.chartArea.height
      : parseFloat(options.radius.toLocaleString());
    const innerRadius = options.cutout.toString().indexOf("%")
      ? (parseFloat(options.cutout.toString()) / 100) * this.chart.chartArea.height
      : parseFloat(options.cutout.toLocaleString());

    const { radiusPercentage, widthPercentage, lengthPercentage, color } = options.needle;

    const needleRadius = (radiusPercentage / 100) * this.chart.chartArea.width;
    const needleWidth = (widthPercentage / 100) * this.chart.chartArea.width;
    const needleLength = (lengthPercentage / 100) * (outerRadius - innerRadius) + innerRadius;
    // center
    const { dx, dy } = this.getTranslation(this.chart);
    // interpolate
    const origin = this.getAngle(this.chart, previous?.valuePercent ?? 0);
    // TODO valuePercent is in current.valuePercent also
    const target = this.getAngle(this.chart, this.getValuePercent(dataset, dataset.value));
    const angle = ((-180 + origin + (target - origin) * ease) * Math.PI) / 180;

    // draw
    ctx.save();
    ctx.translate(dx, dy);
    ctx.rotate(angle);
    ctx.fillStyle = color;
    // draw circle
    ctx.beginPath();
    ctx.ellipse(0, 0, needleRadius, needleRadius, 0, 0, 2 * Math.PI);
    ctx.fill();
    // draw needle
    ctx.beginPath();
    ctx.moveTo(0, needleWidth / 2);
    ctx.lineTo(needleLength, 0);
    ctx.lineTo(0, -needleWidth / 2);
    ctx.fill();
    ctx.restore();
  }
  _drawValueLabel(ease: number) {
    const options = this._options();
    if (!options.valueLabel.display) {
      return;
    }
    const { ctx, config } = this.chart;
    const { defaultFontFamily } = options;
    const dataset = config.data.datasets[this.index] as GaugeControllerDatasetOptions;
    const {
      formatter,
      fontSize,
      fontWeight,
      color,
      backgroundColor,
      borderRadius,
      padding,
      bottomMarginPercentage,
      leftMarginPercentage,
    } = options.valueLabel;

    const bottomMargin = (bottomMarginPercentage / 100) * this.chart.chartArea.height;
    const leftMargin = (leftMarginPercentage / 100) * this.chart.chartArea.width;

    const fmt = formatter || (value => value);
    const valueText = fmt(dataset.value)?.toString();
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";

    if (fontSize || fontWeight) {
      ctx.font = `${fontWeight} ${fontSize}px ${defaultFontFamily} `;
    }

    // const { width: textWidth, actualBoundingBoxAscent, actualBoundingBoxDescent } = ctx.measureText(valueText);
    // const textHeight = actualBoundingBoxAscent + actualBoundingBoxDescent;

    const { width: textWidth } = ctx.measureText(valueText);
    // approximate height until browsers support advanced TextMetrics
    const textHeight = Math.max(ctx.measureText("m").width, ctx.measureText("\uFF37").width);

    const x = -(padding.left + textWidth / 2);
    const y = -(padding.top + textHeight / 2);
    const w = padding.left + textWidth + padding.right;
    const h = padding.top + textHeight + padding.bottom;

    // center
    let { dx, dy } = this.getTranslation(this.chart);

    // add rotation
    const rotation = options.rotation % (Math.PI * 2.0);
    dx += leftMargin * Math.cos(rotation + Math.PI / 2);
    dy += bottomMargin * Math.sin(rotation + Math.PI / 2);

    // draw
    ctx.save();
    ctx.translate(dx, dy);

    // draw background
    ctx.beginPath();

    this._roundedRect(ctx, x, y, w, h, borderRadius);
    ctx.fillStyle = backgroundColor;
    ctx.fill();

    // draw value text
    ctx.fillStyle = color || options.defaultFontColor;
    const magicNumber = 0.075; // manual testing
    ctx.fillText(valueText, 0, textHeight * magicNumber);

    ctx.restore();
  }
  _roundedRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }
  _options(): IGaugeChartOptions {
    // This shouldn't be needed but form some reason this.chart.options doesn't have the defaults in it.
    return this.chart.options as IGaugeChartOptions;
    //return Object.assign({}, Gauge.defaults as Partial<IGaugeChartOptions>, this.chart.options) as IGaugeChartOptions;
  }
  //// overrides
  override update(reset: UpdateMode) {
    const dataset = this.chart.config.data.datasets[this.index] as GaugeControllerDatasetOptions;
    dataset.minValue = dataset.minValue || 0;

    const meta = this.getMeta() as any;
    const initialValue = {
      valuePercent: 0,
    };
    this.ease = 0;
    if (reset == "reset") {
      meta.previous = null;
      meta.current = initialValue;
    } else {
      meta.previous = meta.current || initialValue;
      meta.current = {
        valuePercent: this.getValuePercent(dataset, dataset.value),
      };
    }
    super.update(reset);
  }
  override updateElement(element: any, index: number | undefined, properties: AnyObject, mode: UpdateMode) {
    super.updateElement(element, index, properties, mode);
    // TODO handle reset and options.animation
    const dataset = this.getDataset() as GaugeControllerDatasetOptions;
    const { data } = dataset;

    // scale data
    const previousValue = index === 0 ? dataset.minValue : data[index - 1];
    const value = data[index];

    const startAngle = this.getAngle(this.chart, this.getValuePercent(dataset, previousValue));
    const endAngle = this.getAngle(this.chart, this.getValuePercent(dataset, value));
    const circumference = endAngle - startAngle;
    element._model = {
      ...element._model,
      startAngle,
      endAngle,
      circumference,
    };
  }
  override draw() {
    super.draw();
    this.ease += 0.1;
    this.ease = Math.min(this.ease, 1);

    this._drawNeedle(this.ease);
    this._drawValueLabel(this.ease);
  }
  ease = 0;
}

Gauge.id = "gauge";
Gauge.defaults = Object.assign({}, DoughnutController.defaults, {
  needle: {
    // Needle circle radius as the percentage of the chart area width
    radiusPercentage: 2,
    // Needle width as the percentage of the chart area width
    widthPercentage: 3.2,
    // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
    lengthPercentage: 80,
    // The color of the needle
    color: "rgba(0, 0, 0, 1)",
    // How far in degrees the needle rotates past the 0/180 mark to show that it's out of range.
    maxOverflow: 5,
  },
  valueLabel: {
    fontSize: undefined,
    fontWeight: undefined,
    display: true,
    formatter: null,
    color: "rgba(255, 255, 255, 1)",
    backgroundColor: "rgba(0, 0, 0, 1)",
    borderRadius: 5,
    padding: {
      top: 5,
      right: 5,
      bottom: 5,
      left: 5,
    },
    bottomMarginPercentage: 0,
    leftMarginPercentage: 0,
  },
  animation: {
    duration: 1000,
    animateRotate: true,
    animateScale: false,
  },
  /**
   *  The rotation of the chart, where the first data arc begins.
   */
  rotation: -90,
  /**
   *  The percentage of the chart that we cut out of the middle.
   */
  cutout: "50%",
  /**
   *  The total circumference of the chart.
   */
  circumference: 180,
  //legend: {
  //  display: false,
  //},
  //tooltips: {
  //  enabled: false,
  //},
});

Chart.register(Gauge);
